// mouse.js


// by convention, composable function names start with "use"
const tooltipPosition = ref({ show: false, x: 0, y: 0, owner: '', type: '4map'}); //4map, 4chart

export function useTooltip() {

  const show = (x,y,owner,type) => {
    if(type===undefined) type = '4map';
    tooltipPosition.value.show = true
    tooltipPosition.value.x = x+4;
    tooltipPosition.value.y = y+4;
    tooltipPosition.value.owner = owner;
    tooltipPosition.value.type = type
  };

  const hide = () => {
    tooltipPosition.value.show = false;    
    tooltipPosition.value.type = '4map';
  }

 
  return { show, hide, tooltipPosition};
}